<template>
	<div>

	</div>
</template>

<script>
	export default {
		name: 'BbsDetail'
	}
</script>